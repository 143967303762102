@import url(https://fonts.googleapis.com/css2?family=Baskervville:ital@0;1&family=Open+Sans:ital,wght@0,300;0,400;0,600;1,300;1,400;1,600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: "Open Sans", sans-serif;
  color: white;
  background-color: #282c34;
}

.app-container {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  font-family: Baskerville, Baskervville, serif;
  font-size: calc(30px + 4vmin);
  font-style: italic;
  font-weight: 400;
}

.subtitle {
  font-size: calc(5px + 3vmin);
  font-weight: 100;
}

